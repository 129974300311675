import OpenRequests from "./OpenRequests";
import useFetch from "../useFetch";
import NoRequest from "./NoRequest";

const SnglPropOpnReqLoad = (props) => {

    const { REACT_APP_ALL_MAINTENANCE_SINGLE_PROPERTY } = process.env;
    const { data:requests, isPending, error } = useFetch(REACT_APP_ALL_MAINTENANCE_SINGLE_PROPERTY + "propertyid=" + props.property_id);
    
    return (
        <div className="maintenance">
            <br />
            <h2>Open Requests</h2>
            <div className="open-req-container">

                {/* we need to figure out conditional render of requests and no requests below. */}

                { requests && requests.length ? <OpenRequests requests = { requests } /> : <NoRequest />}
                { isPending && <div>Loading...</div>}
                { error && <div> { error } </div> }    

            </div>
        </div>
    );
}
  
export default SnglPropOpnReqLoad; 