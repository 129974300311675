import { useParams } from "react-router";
import useFetch from "../useFetch";
import SnglPropOpnReqLoad from "../maintenance/SnglPropOpnReqLoad";

const PropertyDetails = () => {
    const { id } = useParams();
    const { REACT_APP_SINGLE_PROPERTY_PATH } = process.env;
    const { data:property, error, isPending } = useFetch( REACT_APP_SINGLE_PROPERTY_PATH + 'propertyid='+  id);
    return (
        <div className="frame">
            { isPending && <div>Loading...</div> }
            { error && <div> { error } </div> }
            { property && (
                <article>
                    <h2>Address: { property[0].street_address } </h2>
                    <p>City: { property[0].city }</p>
                    <p>State: { property[0].state }</p>
                    <p>Zipcode: { property[0].zipcode }</p>
                    <p>Rent: { property[0].rent } </p>
                    <p>Open Request: { property[0].openIssues } </p>
                    <p>Primary Contact: { property[0].primaryContact } </p>
                    <p>Property ID: { property[0].property_id }</p>
                </article>
            )} 

            <br />
            <SnglPropOpnReqLoad property_id={ id }/>
        </div>
    );
}
 
export default PropertyDetails;