import CreateMaintenance from "./CreateMaintenance";
import OpenReqLoad from "./OpenReqLoad";

const Maintenance = () => {
    return (
        <div>
        <OpenReqLoad />
        <CreateMaintenance />
        </div>
    );
}
 
export default Maintenance;