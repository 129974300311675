import useFetch from "../useFetch";



const OpenUrgentIssues = () => {
    const { REACT_APP_URGENTISSUESKPI_PATH } = process.env;
    const { data:value, error, isPending } = useFetch(REACT_APP_URGENTISSUESKPI_PATH);

    return (
        <div>
            { error && <div>{ error }</div> }
            { isPending && <div>Loading...</div> }
            { value && <div>{ value[0].urgentIssuesKPI }</div> }
        </div>
    );
}
 
export default OpenUrgentIssues;