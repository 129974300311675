import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useCookies } from "react-cookie";

const NewMessage = () => {
   
    const [isPendingInsert, setIsPendingInsert] = useState(false)
    const history = useHistory();
    const [message, setMessage] = useState("Enter your message to the team here!")
    const { id } = useParams();
    const [cookies, setCookies] = useCookies();
    const uid = cookies.user;
    const { REACT_APP_POST_CHAT } = process.env
    const maintid = id;

    const handleSubmit = (e) => {
         e.preventDefault()
         const chat = { message, maintid }
         setIsPendingInsert(true)
         fetch(REACT_APP_POST_CHAT + 'uid=' + uid, {
             method: 'POST',
             credentials: 'include',
             headers: { "Content-Type" : "application/json "},
             body: JSON.stringify(chat)
         })
         .then(() => {
             console.log('success')
             setIsPendingInsert(false)
             history.go(-1)
         })
    }
   
    return (
        <form className="new-chat-element">
            <label className="new-chat-label">Message:</label>
            <br />
            <textarea
                className="new-chat-element new-chat-textarea"
                maxLength="250"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <br />
            <button onClick={ handleSubmit }>Send</button>
        </form>
    );
}
 
export default NewMessage;