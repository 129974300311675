import useFetch from "../useFetch";


const AutoApproveKpi = () => {

    const { REACT_APP_AUTOAPPROVE_KPI } = process.env;
    const { data:value, error, isPending } = useFetch(REACT_APP_AUTOAPPROVE_KPI)

    return (
        <div>
            { isPending && <div>Loading...</div> }
            { error && <div>{ error }</div> }
            { value && <div>$ { value[0].auto_approve_amt }</div>}
        </div>
    );
}
 
export default AutoApproveKpi;