import { useState } from "react";
import GetGeneralType from './GetGeneralType.js'
import useFetch from '../useFetch';
import DropdownProperties from "./DropdownProperties.js";
import GetPriorities from "./GetPriorities.js";
import  { useHistory } from "react-router-dom"
import { useCookies } from "react-cookie";

const CreateMaintenance = () => {
    const [property, setProperty] = useState("")
    const [priority, setPriority] = useState(1)
    const [gtype, setGtype] = useState("")
    const [stype, setStype] = useState("")
    const [description, setDescription] = useState("")
    const [isPendingInsert, setIsPendingInsert] = useState(false)
    const history = useHistory();
    const [cookies, setCookies] = useCookies();
    const id = cookies.user;

    const { REACT_APP_PROPERTY_PATH, REACT_APP_POST_NEWREQUEST, REACT_APP_MAINTTYPE_PATH, REACT_APP_MAINTPRIORITY_PATH } = process.env;

    /***** API Calls  *****/
    const { data: general_type , isPending, error } = useFetch(REACT_APP_MAINTTYPE_PATH);
    const { data: properties , isPending2, error2 } = useFetch(REACT_APP_PROPERTY_PATH);
    const { data: priorities, isPending3, error3 } = useFetch(REACT_APP_MAINTPRIORITY_PATH);

    const handleSubmit = (e) => {
        e.preventDefault()
        let created_by = id
        const maint_req = { property, priority, gtype, stype, description, created_by }

        setIsPendingInsert(true)

        fetch(REACT_APP_POST_NEWREQUEST, {
            method: 'POST',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(maint_req)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })
    }

    /***** Get Subtypes from User Selected General Type  *****/
    let options = null
    let temp = []

    if(gtype) {
        temp = general_type.filter(type => type.parent_id === parseInt(gtype))
        if(temp){
            options = temp.map((type) => (
            <option value={type.maint_type_id} key={type.maint_type_id}>{type.sub_type}</option>
            ))
        }
    }

    /***** Start Form  *****/
    return ( 
        <div className="createMaintenance">
            <h2>Create a New Maintenance Request</h2>
            <form onSubmit={handleSubmit}>
                <label>Property: </label>
                <select
                    value={property}
                    onChange={(e) => setProperty(e.target.value)}
                    required
                >   
                    <option value="">-- select a property --</option>
                    {error2 && <option>{ error }</option>}
                    {isPending2 && <option>Loading...</option>}
                    {properties && <DropdownProperties properties={properties} />}
                </select>
                <label>Priority: </label>
                <select
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                    required
                >
                    <option value="">-- select a priority --</option>
                    { error3 && <option>{ error }</option>}
                    { isPending3 && <option>Loading...</option>}
                    { priorities && <GetPriorities priorities={priorities} />}
                </select>
                <label>Category: </label>
                <select
                    value={gtype}
                    onChange={(e) => setGtype(e.target.value)}
                    required
                >
                    <option value="">Select Request Category</option>
                    {error && <option>{ error }</option>}
                    {isPending && <option>Loading...</option>}
                    {general_type && <GetGeneralType general_type={general_type.filter((type) => type.parent_id === 0)} />}
                </select>

                <label>Sub Category: </label>
                <select
                    value={stype}
                    onChange={(e) => setStype(e.target.value)}
                    required
                >
                    {!options && <option value="">Please Select Category First</option>}
                    {options && <option value="">Please Select Sub Category</option>}
                    {options}
                </select>
                <label>Request Description:</label>
                <textarea
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                { !isPendingInsert && <button>Submit</button> }
                { isPendingInsert && <button disabled>Creating New Maintenance Request</button> }
            </form>
            
        </div>
     );
}
 
export default CreateMaintenance;