import { useState } from "react";
import { Link } from "react-router-dom";

const OpenRequests = (props) => {
    const reqList = props.requests;
    const [reqStart, setReqStart] = useState(0);
    const [reqLimit, setReqLimit] = useState(5);

    const handleClickMore = () => { 
        setReqLimit( reqLimit + 5)
        setReqStart( reqStart + 5)
    };

    const handleClickLess = () => { 
        setReqLimit( reqLimit - 5)
        setReqStart( reqStart - 5)
    };

    return (
        <div className="open-requests">
            { reqStart===0? <button disabled onClick = { handleClickLess }>First</button> : <button onClick = { handleClickLess }>Previous</button> }
            { reqList.length>reqLimit? <button onClick = { handleClickMore }>Next</button> : <button disabled onClick = { handleClickMore }>Last</button>}
            
            <br />

            {reqList.slice(reqStart, reqLimit).map((requests) => (
                <Link to={`/request/${requests.maintenance_id}`} key={requests.maintenance_id} className="request-preview">
                        <div className="preview-address" id="a">
                            <h4>{requests.street_address}</h4>
                        </div>
                        <div className="preview-category" id="b">
                            <h4>{requests.general_type} { requests.sub_type }</h4>
                        </div>
                        <div className="preview-priority" id="c">
                            <h4>Rating: { requests["priority"] }</h4>
                        </div>
                        <div className="preview-quote" id="d">
                            <h4>Quote: { requests.quote_amount===0 ? "N/A" : requests.quote_amount }</h4>
                        </div>
                        <div className="preview-quote-approval" id="e">
                            <h4>Status: { requests.status }</h4>
                        </div>
                        <div className="preview-description" id="f"> 
                            <p>Description: { requests.init_description }</p>
                        </div>
                </Link>  
            ))}
        </div>
    );
}
 
export default OpenRequests;