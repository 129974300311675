import TopProps from './properties/TopProps';
import AccountOverview from './account_pages/AccountOvrvw';
import KpiDash from './kpi-components/KpiDash';
import OpenReqLoad from './maintenance/OpenReqLoad';



const Home = () => {
    return (
        <div>
            <AccountOverview /> 
            <br />
            <KpiDash />
            <br /> 
            <OpenReqLoad />
            <br />
            <TopProps  />
       </div>
    );
}
 
export default Home;