import { useHistory } from "react-router-dom";
import { useState } from "react";
import ChatLoader from "../chat/ChatLoad";
import NewMessage from "../chat/NewChat";
import { useParams } from "react-router";

const { REACT_APP_CURRENT_USER_GROUP } = process.env;

const ReqDetail = (props) => {
    const history = useHistory();
    const [isPendingInsert, setIsPendingInsert] = useState(false);
    const { REACT_APP_POST_QUOTE_APPROVAL } = process.env;
    const [quoteAmt, setQuoteAmt] = useState('enter quote amount');
    const { id } = useParams();
    const { REACT_APP_QUOTE_SUBMIT, REACT_APP_QUOTE_APPROVAL, REACT_APP_QUOTE_REJECT, REACT_APP_L_SLN_APRV, REACT_APP_L_SLN_RJCT,REACT_APP_T_SLN_APRV, REACT_APP_T_SLN_RJCT,REACT_APP_MARK_JOB_COMPLETE } = process.env;
    const request = props.request[0];

    /****  Landlord Button Control  ****/
    const handleQuoteApprovalClick = (e) => {
        const maintenance_id = { "maintid" : request.maintenance_id };
        setIsPendingInsert(true)
        fetch(REACT_APP_QUOTE_APPROVAL + 'maintid=' + id, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(maintenance_id)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })
    }

    const handleQuoteRejectClick = (e) => {
        const maintenance_id = request.maintenance_id
        console.log('firing')
        setIsPendingInsert(true)
        let status_change = { "status" : e.target.value }
        fetch(REACT_APP_QUOTE_REJECT + 'maintid=' + id, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(status_change)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })
    }

    const handleLSlnAprvlClick = (e) => {
        const maintenance_id = { "maintid" : request.maintenance_id };
        setIsPendingInsert(true)
        let status_change = { "status" : e.target.value }
        fetch(REACT_APP_L_SLN_APRV + 'maintid=' + id, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(maintenance_id)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })  
    }

    const handleLSlnRjctClick = (e) => {
        const maintenance_id = request.maintenance_id
        setIsPendingInsert(true)
        let status_change = { "status" : e.target.value }
        fetch(REACT_APP_L_SLN_RJCT + 'maintid=' + id, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(status_change)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })  
    }

    /****  Tenant Button Control  ****/
    const handleTSlnAprvlClick = (e) => {
        const maintenance_id = { "maintid" : request.maintenance_id };
        setIsPendingInsert(true)
        let status_change = { "status" : e.target.value }
        fetch(REACT_APP_T_SLN_APRV + 'maintid=' + id, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(maintenance_id)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })  
    }

    const handleTSlnRjctClick = (e) => {
        const maintenance_id = request.maintenance_id
        setIsPendingInsert(true)
        let status_change = { "status" : e.target.value }
        fetch(REACT_APP_T_SLN_RJCT + 'maintid=' + id, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(status_change)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })
    }

    const handleQuoteSubmit = (e) => {
        e.preventDefault()
        let created_by = id
        const quote = { "maintid" : request.maintenance_id, "quoteamount" : quoteAmt };
        setIsPendingInsert(true)
        fetch(REACT_APP_QUOTE_SUBMIT, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(quote)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })
    }

    const handleMarkComplete = (e) => {
        e.preventDefault()
        let created_by = id
        const maintenance_id = { "maintid" : request.maintenance_id };
        setIsPendingInsert(true)
        fetch(REACT_APP_MARK_JOB_COMPLETE, {
            method: 'PATCH',
            credentials: 'include',
            headers: { "Content-Type" : "application/json "},
            body: JSON.stringify(maintenance_id)
        })
        .then(() => {
            console.log('success')
            setIsPendingInsert(false)
            history.go(-1)
        })
    }

    return (
        <div className="maintenance-detail">
            <h2>Maintenance Number: { request.maintenance_id }</h2>
            <span className="inline"><h3>Status: </h3><p> { request.status }</p></span>
            <span className="inline"><h3>Workflow:</h3><p> New - Quoted - Repair Pending - Repair Approval - Closed</p></span>
            <div className="new-req-summary group">
                <h3 className="address-label">Address: </h3>
                <p className="address">{ request.street_address + ', ' + request.city }</p>
                <h3 className="tenant_label">Tenant: </h3>
                {
                    request.tenant !== null 
                    ? <p className="tenant">{ request.tenant }</p>
                    : <p className="tenant">Abandoned</p>
                }
                <h3 className="tenant-phone-label">Phone: </h3>
                <p className="tenant-phone">{ request.t_phone }</p>
                { 
                    REACT_APP_CURRENT_USER_GROUP === "3" 
                    ? <div></div> 
                    : <h3 className="owner-label">Landlord: </h3>
                }
                { 
                    REACT_APP_CURRENT_USER_GROUP === "3" 
                    ? <div></div> : 
                    <p className="owner">{ request.landlord }</p> 
                }
                                { 
                    REACT_APP_CURRENT_USER_GROUP === "3" 
                    ? <div></div> 
                    : <h3 className="owner-phone-label">Phone: </h3>
                }
                { 
                    REACT_APP_CURRENT_USER_GROUP === "3" 
                    ? <div></div> : 
                    <p className="owner-phone">{ request.l_phone }</p> 
                }
                <h3 className="issue-label">Issue: </h3>
                <p className="issue">{ request.sub_type }</p>
                <h3 className="priority-label">Priority: </h3>
                <p className="priority">{ request.priority }</p>
                <h3 className="created-label">Created: </h3>
                <p className="created">{ request.create_date }</p>
                <h3 className="description-label">Description</h3>
                <p className="description">{ request.init_description }</p>
            </div>
            <div className="quote-summary group">
                <h3 className="contact-label">Contact: </h3>
                <p className="contact">{ request.contractor }</p>
                { 
                    REACT_APP_CURRENT_USER_GROUP === "3" 
                    ? <div></div> 
                    : <h3 className="contact-phone-label">Phone: </h3>
                }
                { 
                    REACT_APP_CURRENT_USER_GROUP === "3" 
                    ? <div></div> : 
                    <p className="contact-phone">{ request.c_phone }</p> 
                }
                <h3 className="quote-label">Quote: </h3>
                <p className="quote">$ { request.quote_amount === null ? "N/A" : request.quote_amount }</p>
                <h3 className="quote-approval-label">Approved: </h3>
                <p className="quote-approval">{ request.approval_flag === 1 ? "Yes" : "No" }</p>
                <h3 className="quote-notes-label">Notes: </h3>   
                <p className="quote-notes">{ request.quote_description }</p> 
                { request.maint_status_id===10 && REACT_APP_CURRENT_USER_GROUP === "2" 
                    ? <div className="quote-form">
                        <form onSubmit={ handleQuoteSubmit }>
                            <h3>Quote</h3>
                            <input
                            type="number"
                            value={quoteAmt}
                            required 
                            id="quote_amt"
                            onChange={(e) => setQuoteAmt(e.target.value)}
                            />
                            <button className="quote-button">Submit</button>
                        </form>
                    </div>
                    : <div></div>}          
                { request.maint_status_id===30 && REACT_APP_CURRENT_USER_GROUP === "1" ? <div className="quote-button"><button onClick={ handleQuoteApprovalClick } value="60" className="approval-button">approve</button> <button onClick={ handleQuoteRejectClick } value="29" className="reject-button">reject</button></div>: <div></div>}          
            </div>
            <div className="solution-summary group">
                <h3 className="solution-date-label">Solution:</h3>
                <p className="solution-date">{ request.close_date === null ? "In Process" : request.close_date}</p>
                <h3 className="solution-approval-label">Approval: </h3>
                <p className="solution-approval">{ request.maint_status_id === 100 ? "Approved" : "Incomplete" }</p>
                <h3 className="solution-pic-label">Picture: </h3>
                <a href="" target="_blank" alt="pic" rel="noreferrer" className="solution-pic">click here</a>
                {/* <h3 className="planned-date-label">Planned: </h3>
                <p className="planned-date">{ ticket.planned_fix_date !== null && ticket.solution_approval === null ? ticket.planned_fix_date : "" }</p>
                <h3 className="solution-notes-label">Notes: </h3>
                <p className="solution-notes">{ ticket.solution_notes }</p> */}
                { request.maint_status_id===80 && request.landlord_approve=== 0 && REACT_APP_CURRENT_USER_GROUP === "1" ? <div className="solution-button"><button onClick={ handleLSlnAprvlClick } value="100" className="approval-button">approve</button> <button onClick={ handleLSlnRjctClick } value="89" className="reject-button">reject</button></div>: <div></div>}
                { request.maint_status_id===60 && REACT_APP_CURRENT_USER_GROUP === "2" ? <div className="solution-button"><button onClick={ handleMarkComplete } value="80" className="approval-button">Job Complete!</button></div>: <div></div>}
                { request.maint_status_id===80 && request.tenant_approve=== 0 && REACT_APP_CURRENT_USER_GROUP === "3" ? <div className="solution-button"><button onClick={ handleTSlnAprvlClick } value="100" className="approval-button">approve</button> <button onClick={ handleTSlnRjctClick } value="99" className="reject-button">reject</button></div>: <div></div>}
            </div>  
            <div className="chat group">
                <ChatLoader />
            </div>
            <div className="new-chat group">
                <NewMessage />
            </div>
        </div>
    );
}
 
export default ReqDetail;