import './maintenance/maintenance.css';
import './account_pages/account.css';
import './chat/chat.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './common/Navbar';
import Settings from './settings/Settings';
import Footer from './common/Footer';
import Home from './Home';
import CreateMaintenance from './maintenance/CreateMaintenance'
import TopProps from './properties/TopProps';
import PageNotFound from './error_pages/404';
import PropertyDetails from './properties/PropertyDetails';
import Maintenance from './maintenance/Maintenance';
import ReqDetailLoad from './maintenance/ReqDetLoad';

function App() {
  return ( 
    <Router>
        <Navbar />
        <div className="core-content">
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/settings'>
            <Settings />
          </Route>
          <Route exact path="/request/:id">
              <ReqDetailLoad />
          </Route>
          <Route path='/maintenance'>
            <Maintenance />
          </Route>
          <Route path='/properties/:id'>
            <PropertyDetails />
          </Route>
          <Route path='/properties'>
            <TopProps />
          </Route>
          <Route exact path="/createMaintenance">
              <CreateMaintenance />
          </Route>
          {/* Keep 404 last */}
          <Route path='*'>
            <PageNotFound />
          </Route>
        </Switch>
        </div>
        <Footer />
    </Router>
  );
}

export default App;
