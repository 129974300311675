import AcctSmry from "./AcctSmry";
import NotifyRentButton from "./NotifyButton";
import SendFeedback from "./SendFeedback";

const AccountOverview = () => {


    return (
        <div className="frame">
            <div className='acct-container'>
                <AcctSmry />
                {/* <NotifyRentButton /> */}
                <SendFeedback />
            </div>
        </div>
    );
}
 
export default AccountOverview;