import useFetch from "../useFetch";

const ActiveLockouts = () => {

    const { REACT_APP_LOCKOUTKPI_PATH } = process.env
    const { data:value, error, isPending } = useFetch(REACT_APP_LOCKOUTKPI_PATH);

    return (
        <div>
            { error && <div>{ error }</div> }
            { isPending && <div>Loading...</div> }
            { value && <div>{ value[0].lockoutKPI }</div> }
        </div>
    );
}
 
export default ActiveLockouts;