import PropertyPreviewList from "./PropertyList";
import useFetch from "../useFetch";

const TopProps = () => {

    const { REACT_APP_PROPERTY_PATH } = process.env;   
    const { data: propertyList, isPending, error } = useFetch(REACT_APP_PROPERTY_PATH);

 
    return (
        <div className='frame'>
            <h2>Properties</h2>

            {/*Pass Property Display List in below... */}
            { error && <div>{ error }</div> }
            { isPending && <div>loading...</div> }
            { propertyList && <PropertyPreviewList propertyList = { propertyList }/> }

            {/* <h2>Properties with open issues</h2> */}
            {/* Pass in a filtered list of properties below */}
            {/* { propertyList && <PropertyPreviewList propertyList = { propertyList.filter((propertyList) => propertyList.openIssues !== 0 ) } /> } */}

        </div>
    );
}
 
export default TopProps;