import { useState } from "react";
import { Link } from "react-router-dom";


const PropertyPreviewList = (props) => {

    const propertyList = props.propertyList;
    const [propStart, setPropStart] = useState(0);
    const [propLimit, setPropLimit] = useState(5);

    const handleClickMore = () => { 
        setPropLimit( propLimit + 5)
        setPropStart( propStart + 5)
    };

    const handleClickLess = () => { 
        setPropLimit( propLimit - 5)
        setPropStart( propStart - 5)
    };


    return (
       <div className="property-list">

           { propStart===0? <button disabled onClick = { handleClickLess }>First</button> : <button onClick = { handleClickLess }>Previous</button> }
           { propertyList.length>propLimit? <button onClick = { handleClickMore }>Next</button> : <button disabled onClick = { handleClickMore }>Last</button>}

           <br />

           { propertyList.slice(propStart, propLimit).map((propertyList) => (
               <div className="property-preview" key={propertyList.property_id}>
                   <Link to={`/properties/${propertyList.property_id}`}>
                   <h3>{ propertyList.street_address }</h3>
                   <div className="prop-preview-content">
                       <div className="prop-preview-rent"><p>Rent: { propertyList.rent }</p></div>
                       <div className="prop-preview-issueCount"><p>Open Issues: { propertyList.numOfIssues }</p></div>
                   </div>
                   </Link>
               </div>
           ))}
                    

       </div>


    );
}
 
export default PropertyPreviewList;