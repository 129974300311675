import KpiTile from "./KpiTile";
import { FaMoneyBillWaveAlt, FaHammer, FaExclamationTriangle, FaLock } from 'react-icons/fa';
import AutoApproveKpi from "./AutoApproveKpi";
import OpenRequestsCurrent from "./OpenRequestsKpi";
import ActiveLockouts from "./ActiveLockouts";
import OpenUrgentIssues from "./UrgentIssuesOpen";

const KpiDash = () => {
    
    const kpiList = [
        {name: 'Open Requests', img: <FaHammer className="kpi-icons"/>, warnValue: 3, query: <OpenRequestsCurrent />, link: '/maintenance', id: 1},
        {name: 'Urgent Issues', img: <FaExclamationTriangle className="kpi-icons"/>, warnValue: 1, query: <OpenUrgentIssues />, link: '/maintenance', id: 2},
        {name: 'Auto-Approve', img: <FaMoneyBillWaveAlt className="kpi-icons"/>, warnValue: 'TBD', query: <AutoApproveKpi />, link: '/settings', id: 3},
        {name: 'Active Lockouts', img: <FaLock className="kpi-icons"/>, warnValue: 1, query: <ActiveLockouts />, link: '/maintenance', id: 4},
        {name: 'Empty Units', img: 'TBD', warnValue: 'TBD', query: 'TBD', link: 'TBD', id: 5},
        {name: 'Late Rent', img: 'TBD', warnValue: 'TBD', query: 'TBD', link: 'TBD', id: 6},
        {name: 'Leases <60', img: 'TBD', warnValue: 'TBD', query: 'TBD', link: 'TBD', id: 7},
        {name: 'Open Estimates', img: 'TBD', warnValue: 'TBD', query: 'TBD', link: 'TBD', id: 8},
        // {name: 'Open Tasks', img: <FaTasks className="kpi-icons"/>, warnValue: 1, query: <OpenTasks />, link: '/maintenance', id: 9},
        // {name: 'Urgent Issues', img: <FaExclamationTriangle className="kpi-icons"/>, warnValue: 1, query: <OpenUrgentIssues />, link: '/maintenance', id: 10},
        // {name: 'Lockouts', img: <FaLock className="kpi-icons"/>, warnValue: 1, query: 'TBD', link: '/maintenance', id: 11},
        // {name: 'Quote Required', img: <FaFileInvoiceDollar className="kpi-icons" />, warnValue: 'TBD', query: <QuoteReq />, link: '/maintenance', id: 12}          
    ];

    return (
        <div className="frame">
            <div className="container">
                <KpiTile kpis={ kpiList } />
            </div>
        </div>
    );
}
 
export default KpiDash;