import useFetch from "../useFetch";

const AcctSmry = () => {
    const { REACT_APP_USER_PATH } = process.env;
    const { REACT_APP_TOTALUNCOLLECTRENT_KPI_PATH } = process.env;
    const { REACT_APP_TOTALCOLLECTEDRENT_KPI_PATH } = process.env;
    const { data:userArray, error, isPending } = useFetch( REACT_APP_USER_PATH);
    // const { data:rentDue, error2, isPending2 } = useFetch(REACT_APP_TOTALUNCOLLECTRENT_KPI_PATH);
    // const { data:rentCollected, error3, isPending3 } = useFetch(REACT_APP_TOTALCOLLECTEDRENT_KPI_PATH);

    return (
        <div className="acctSummary">
            { isPending && <h2>Welcome!</h2>}
            { error && <h2>Error loading user { error }</h2> }
            { userArray && userArray.length ? <h2>Welcome, { userArray[0].fname } { userArray[0].lname }!</h2> : <h2>user does not exist</h2> }
            
            {/* { error2 && <div>{ error2 }</div> } 
            { error3 && <div>{ error3 }</div> } 
            { rentDue && rentCollected && <p>Collected ${rentCollected[0].total_collected_rent_for_month} of ${parseInt(rentCollected[0].total_collected_rent_for_month, 10) + parseInt(rentDue[0].total_uncollected_rent_for_month, 10)} rent. ${ rentDue[0].total_uncollected_rent_for_month } left to collect.</p> } */}
        </div>
    );
}
 
export default AcctSmry;