import './settings.css';
import { FaPeopleArrows, FaUserPlus, FaHome, FaDollarSign } from 'react-icons/fa';

const Settings = () => {

    const settingsList = [
        // {name: 'Security', value: 'Medium', icon: 'src', mailto: "mailto:info@rentablapp.com?subject=Change%20My%20Security", id: 1},
        {name: 'Change Contactors', value: 'click here', icon: <FaPeopleArrows className="setting-icon" />, mailto: "mailto:info@rentablapp.com?subject=Change%20My%20Contractors", id: 2},
        {name: 'New Contractor', value: 'click here', icon: <FaUserPlus className="setting-icon" />, mailto: "mailto:info@rentablapp.com?subject=Add%20a%20Contractor", id: 3},
        // {name: 'Alert Settings', value: 'high', icon: 'src', mailto: "mailto:info@rentablapp.com?subject=Change%20My%20Membership", id: 4},
        {name: 'Add New Property', value: 'click here', icon: <FaHome className="setting-icon" />, mailto: "mailto:info@rentablapp.com?subject=Add%20Property", id: 5},
        // {name: 'Change Payment Settings', value: 'null', icon: 'src', mailto: "mailto:info@rentablapp.com?subject=Change%20My%20Security", id: 6},
        // {name: 'Changle Collection Settings', value: 'high', icon: 'src', mailto: "mailto:info@rentablapp.com?subject=Change%20My%20Security", id: 7},
        {name: 'Change Auto-Approve', value: 'click here', icon: <FaDollarSign className="setting-icon" />, mailto: "mailto:info@rentablapp.com?subject=Change%20Approval%20Threshold", id: 8},
        // {name: 'Membership Level', value: 'Anonymous', icon: 'src', mailto: "mailto:info@rentablapp.com?subject=Change%20My%20Security", id: 9},
    ];


    return (
        <div className="mySettings">
            <div className="settings-list">
                <h2>Settings</h2>
                {settingsList.map((settingsList) => (
                    <a className="setting-container" key={ settingsList.id } href={ settingsList.mailto }>
                        <div className="setting-icon">
                            { settingsList.icon }
                        </div>
                        <div className="setting-field">
                            <h3>{ settingsList.name }</h3>
                        </div>
                        <div className="setting-value">
                            <p>{ settingsList.value }</p>
                        </div>
                    </a>
                ))}
            </div>
        </div>

    );
}
 
export default Settings;