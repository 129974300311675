import useFetch from "../useFetch";

const OpenRequestsCurrent = () => {
    

    const { REACT_APP_OPENMAINTENANCEISSUES_KPI_PATH } = process.env;
    const { data:value, error, isPending } = useFetch(REACT_APP_OPENMAINTENANCEISSUES_KPI_PATH)

    return (
        <div>
            { error && <div>{ error }</div> }
            { isPending && <div>Loading...</div> }
            { value && <div>$ { value[0].costForMaint } ({ value[0].numOfOpenMaintIssues }) </div>}
        </div>
        );
}
 
export default OpenRequestsCurrent;